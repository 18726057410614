// @/i18n/index.js
import Vue from "vue";

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import rules from "./_rules";
import messages from "./_messages";
import store from "@/store";

const i18n = new VueI18n({
  locale: store.getters["config/locale"],
  fallbackLocale: "en",
  messages,
  pluralizationRules: rules,
});

export default i18n;
