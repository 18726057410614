export default {
  ru: {
    login: {
      email: "Почта",
      password: "Пароль",
      submit: "Войти",
    },
    pagination: {
      prev: "«",
      next: "»",
    },
    search: "поиск",
    client: "клиент",
  },
  en: {
    login: {
      email: "Email",
      password: "Password",
      submit: "Login",
    },
    pagination: {
      prev: "«",
      next: "»",
    },
    search: "search",
    client: "client",
  },
};
