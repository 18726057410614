import "./legacy";

import "./style.css";
import "./scss/index.scss";

// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";

import Vue from "vue";
import store from "./store";
import router from "./router";
import i18n from "./i18n";

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);

import Notifications from "vue-notification";
Vue.use(Notifications);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import PrimeVue from "primevue/config";
Vue.use(PrimeVue);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import App from "./App.vue";

Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
