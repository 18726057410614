<template>
  <Menubar :model="items" class="border-none border-noround">
    <template #start>
      <img alt="logo" src="@/assets/logo.svg" height="40" class="mr-2" />
    </template>

    <template #end>
      <div class="flex">
        <portal-target name="portal-navbar" />
        <navbar-user />
      </div>
    </template>
  </Menubar>
</template>

<script>
import routes from "../router/_routes";
import Menubar from "primevue/menubar";
import NavbarUser from "./NavbarUserComponent.vue";

export default {
  components: {
    Menubar,
    NavbarUser,
  },
  data: () => ({}),
  computed: {
    routes() {
      return routes.filter((r) => r.meta.isTop);
    },
    items() {
      return this.routes.map(({ name: label, path: to, icon }) => ({
        label,
        to,
        icon,
      }));
    },
  },
};
</script>
