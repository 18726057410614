//! @/store/_hosts.js

import { host as api } from "@/api";

export default {
  namespaced: true,
  state: {
    items: [],
    offset: 0,
    total: 0,
    isLoading: false,
  },
  mutations: {
    SET_LOADED(state) {
      state.isLoading = false;
    },
    SET_LOADING(state) {
      state.isLoading = true;
    },
    SET_ITEM(state, payload) {
      const index = state.items.findIndex((item) => item.id === payload.id);

      if (index === -1) {
        state.items = [{ ...payload }, ...state.items];
      } else {
        state.items[index] = { ...payload };
      }

      state.items = [...state.items];
    },
    SET_ITEMS(state, items) {
      state.items = items;
    },
  },
  actions: {
    getList({ commit }) {
      commit("SET_LOADING");

      api
        .list()
        .then(({ data }) => commit("SET_ITEMS", data.data))
        .catch((e) => console.log(e))
        .then(() => commit("SET_LOADED"));
    },
    store({ commit }, payload) {
      commit("SET_LOADING");

      api
        .store(payload)
        .then(({ data: { data } }) => commit("SET_ITEM", data))
        .catch((e) => console.log(e))
        .then(() => commit("SET_LOADED"));
    },
    update({ commit }, payload) {
      commit("SET_LOADING");

      api
        .update(payload.id, payload)
        .then(({ data: { data } }) => commit("SET_ITEM", data))
        .catch((e) => console.log(e))
        .then(() => commit("SET_LOADED"));
    },
  },
  getters: {
    item: (state) => (id) => state.items.find((item) => item.id === id),
  },
};
