<template>
  <!-- <button class="btn btn-light" v-if="isLogged" @click="logout">
    <i class="fas fa-sign-out-alt"></i>
  </button> -->

  <Button
    class="ml-2"
    v-if="isLogged"
    @click="logout"
    icon="pi pi-power-off"
    :style="{ 'margin-left': '0 .5em' }"
  />
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Button from "primevue/button";

export default {
  components: { Button },
  data: () => ({}),
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["isLogged"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),
  },
};
</script>
