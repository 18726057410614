<template>
  <nav aria-label="page navigation" v-if="last > 1">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: disabled || value === 1 }">
        <a class="page-link" href="#" @click.prevent="prev">
          {{ $t("pagination.prev") }}
        </a>
      </li>

      <li
        class="page-item"
        :class="{ active: value === p, disabled: disabled || p === '' }"
        v-for="p in pages"
        :key="p"
      >
        <a class="page-link" href="#" @click.prevent="set(p)" v-if="p">
          {{ p }}
        </a>
        <span class="page-link" v-else>...</span>
      </li>

      <li class="page-item" :class="{ disabled: disabled || value === last }">
        <a class="page-link" href="#" @click.prevent="next">
          {{ $t("pagination.next") }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    last: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    delta: 2,
  }),
  computed: {
    pages() {
      const range = [],
        rangeWithDots = [],
        left = this.value - this.delta,
        right = this.value + this.delta + 1;

      for (let i = 1; i <= this.last; i++) {
        if (i === 1 || i === this.last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      let l;

      range.forEach((i) => {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("");
          }
        }

        rangeWithDots.push(i);
        l = i;
      });

      return rangeWithDots;
    },
  },
  methods: {
    prev() {
      this.set(this.value - 1);
    },
    next() {
      this.set(this.value + 1);
    },
    set(v) {
      if (this.disabled || v < 1 || v > this.last || v === this.value) {
        return;
      }

      this.$emit("input", v);
    },
  },
};
</script>