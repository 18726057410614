<template>
  <div class="layout-default">
    <navbar class="layout-default__navbar navbar" />

    <div class="layout-default__content">
      <div>
        <slot />
      </div>
    </div>

    <div class="layout-default__pagination">
      <portal-target name="portal-pagination" />
    </div>
  </div>
</template>

<script>
import { NavbarComponent as Navbar } from "../components";

export default {
  components: {
    Navbar,
  },
};
</script>
