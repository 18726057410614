//! @/store/_modal.js
export default {
  namespaced: true,
  state: {
    component: null,
    save: () => {},
  },
  mutations: {
    OPEN(state, payload) {
      state.component = payload;
    },
    CLOSE(state) {
      state.component = null;
      state.save = () => {};
    },
  },
  getters: {
    opened: (state) => !!state.component,
  },
};
