//! @/store/_noty.js

export default {
  namespaced: true,
  state: {
    noties: [],
  },
  mutations: {
    SET_NOTY(state, payload) {
      state.noties.push({ type: "", ...payload });
    },
    SET_SUCCESS(state, payload) {
      state.noties.push({ type: "success", ...payload });
    },
    SET_WARNING(state, payload) {
      state.noties.push({ type: "warn", ...payload });
    },
    SET_ERROR(state, payload) {
      state.noties.push({ type: "error", ...payload });
    },
  },
  actions: {},
  getters: {},
};
