//! @/store/_auth.js

import { auth as api } from "@/api";

export default {
  namespaced: true,
  state: {
    user: null,
    isLoading: false,
  },
  mutations: {
    SET_LOADING(state) {
      state.isLoading = true;
    },
    SET_LOADED(state) {
      state.isLoading = false;
    },
    LOGIN(state, payload) {
      state.user = payload;
    },
    LOGOUT(state) {
      state.user = null;
    },
  },
  actions: {
    csrf({ commit }) {
      commit("SET_LOADING");

      return new Promise((resolve, reject) => {
        api
          .csrf()
          .then(() => resolve())
          .catch((error) => reject(error))
          .then(() => commit("SET_LOADED"));
      });
    },
    login({ commit }, payload) {
      commit("SET_LOADING");

      return new Promise((resolve, reject) => {
        api
          .csrf()
          .then(() => api.login(payload))
          .then(() => api.user())
          .then((response) => commit("LOGIN", response.data.user))
          .then(() => resolve())
          .catch((e) => reject(e))
          .then(() => commit("SET_LOADED"));
      });
    },
    logout({ commit }) {
      commit("SET_LOADING");

      return new Promise((resolve, reject) => {
        api
          .logout()
          .then((response) => {
            commit("LOGOUT");
            resolve(response);
          })
          .catch((e) => reject(e))
          .then(() => commit("SET_LOADED"));
      });
    },
    // getUser({ commit }) {
    //   commit("SET_LOADING");

    //   return new Promise((resolve, reject) => {
    //     api
    //       .user()
    //       .then((response) => {
    //         commit("LOGIN", response.data.user);
    //         resolve(response);
    //       })
    //       .catch((error) => reject(error))
    //       .then(() => commit("SET_LOADED"));
    //   });
    // },
  },
  getters: {
    isLogged: (state) => state.user !== null,
    name: (state, getters) =>
      getters.isLogged && state.user.name ? state.user.name : "",
    roles: (state, getters) =>
      getters.isLogged && state.user.roles ? state.user.roles : [],
    isAdmin: (state, getters) =>
      getters.isLogged && getters.roles.indexOf("admin") !== -1,
  },
};
