<template>
  <notifications group="noty" position="bottom left" />
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("noty", ["noties"]),
  },
  watch: {
    noties(list) {
      this.$notify({
        group: "noty",
        ...list[list.length - 1],
      });
    },
  },
};
</script>
