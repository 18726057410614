const requiresAuth = true,
  isTop = true,
  routes = [
    {
      path: "/",
      name: "/",
      icon: "pi pi-user",
      component: () => import("@/views/ClientView"),
      meta: {
        requiresAuth,
        isTop,
      },
    },
    {
      path: "/gallery",
      name: "gallery",
      icon: "pi pi-camera",
      component: () => import("@/views/GalleryView"),
      meta: {
        requiresAuth,
        isTop,
      },
    },
    {
      path: "/project",
      name: "project",
      icon: "pi pi-home",
      component: () => import("@/views/ProjectView"),
      meta: {
        requiresAuth,
        isTop,
      },
    },
    {
      path: "/yandex",
      name: "yandex",
      icon: "pi pi-envelope",
      component: () => import("@/views/YandexMailView"),
      meta: {
        requiresAuth,
        isTop,
      },
    },
    {
      path: "/host",
      name: "host",
      icon: "pi pi-moon",
      component: () => import("@/views/HostView"),
      meta: {
        requiresAuth,
        isTop,
      },
    },
    // {
    //   path: "/entities",
    //   name: "entities",
    //   component: () => import("@/views/EntitiesView"),
    //   meta: {
    //     requiresAuth,
    //     isTop,
    //   },
    // },
    // {
    //   path: "/cbr",
    //   name: "cbr",
    //   component: () => import("@/views/CbrView"),
    //   meta: {
    //     requiresAuth,
    //     isTop,
    //   },
    // },
    // {
    //   path: "/qr",
    //   name: "qr",
    //   component: () => import("@/views/QrCodeView"),
    //   meta: {
    //     requiresAuth,
    //     isTop,
    //   },
    // },
    // {
    //   path: "/user",
    //   name: "users",
    //   component: () => import("@/views/UsersView"),
    //   meta: {
    //     requiresAuth,
    //     isTop,
    //   },
    // },
    {
      path: "/rubles",
      name: "rubles",
      component: () => import("@/views/RublesView"),
      meta: {
        requiresAuth,
        isTop,
      },
    },
    {
      path: "/excel",
      name: "excel",
      component: () => import("@/views/ExcelConvertView"),
      meta: {
        layout: "centered",
        requiresAuth,
        isTop,
      },
    },
    // {
    //   path: "/gallery",
    //   name: "gallery",
    //   component: () => import("@/views/Gallery"),
    //   meta: {
    //     requiresAuth,
    //     isTop,
    //   },
    // },
    {
      path: "/upload",
      name: "upload",
      icon: "pi pi-fw pi-file",
      component: () => import("@/views/UploadView"),
      meta: {
        layout: "centered",
        requiresAuth,
        isTop,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView"),
      meta: {
        layout: "empty",
      },
    },
  ];

export default routes;
