<template>
  <input type="file" :multiple="multiple" @change="handleChange" />
</template>

<script>
import { wrapInArray } from "@/helpers";
import { file as api } from "@/api";

export default {
  components: {},
  props: {
    value: {
      default: undefined,
      validator: (val) =>
        wrapInArray(val).every((v) => v != null && typeof v === "object"),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: null,
    },
  },
  methods: {
    handleChange(e) {
      Array.from(e.target.files)
        .slice(0, this.multiple ? e.target.files.length : 1)
        .forEach((f) =>
          api
            .upload(f)
            .then(({ data }) => {
              if (this.multiple) {
                this.$emit("input", [...this.value, data]);
              } else {
                this.$emit("input", data);
              }
            })
            .catch(() => {})
        );
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="file"] {
  display: none;
}
</style>
