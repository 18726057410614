import { axios } from "@/plugins";
import { downloadUrl } from "@/helpers";

const base = "excel";

export default {
  convert: (payload) => axios.post(`${base}/convert`, payload),
  download: (params) =>
    downloadUrl(axios.getUri({ url: `${base}/download`, params })),
};
