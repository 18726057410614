import axios from "axios";
import store from "@/store";
import router from "@/router";

axios.defaults.baseURL = process.env.VUE_APP_API || "";
axios.defaults.withCredentials = true;

// axios.interceptors.request.use(
//   (config) => {
//     config.headers = {
//       "Content-Type": "application/json",
//     };
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.config && !error.config.__isRetryRequest) {
        store.commit("noty/SET_ERROR", {
          title: `XHR: ${error.response.status}`,
          text: error.response.data.message || "",
        });

        switch (error.response.status) {
          case 401:
          case 419:
            store.commit("auth/LOGOUT");
            router.push("login").catch(() => {});
            break;
        }
      }
      // } else if (error.request) {
      // } else {
    }

    return Promise.reject(error);
  }
);

export default axios;

export const plugin = {
  install(Vue) {
    Vue.prototype.$axios = axios;
  },
};
