//! @/router/index.js

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import store from "@/store";
import routes from "./_routes";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach((to, _, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isLogged"]) {
      const isAdmin = store.getters["auth/isAdmin"];

      if (to.matched.some((record) => record.meta.isAdmin) && !isAdmin) {
        next({ path: "/" });
      } else {
        next();
      }
    } else {
      const query = {};

      if (to.fullPath !== "/") {
        query.redirect = to.fullPath;
      }

      next({
        path: "/login",
        query,
      });
    }
  } else {
    next();
  }
});

export default router;
