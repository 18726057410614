//! @/store/_clients.js

export default {
  namespaced: true,
  state: {
    items: [...Array(100).keys()].map(() => ({
      id: 1,
      username: "romkamix",
      tasks: [
        { id: 1, descr: "dsdgsdg", date: "2024-01-15 22:04:48" },
        { id: 2, descr: "dsdgsdg", date: "2023-01-15 22:04:48" },
        { id: 3, descr: "dsdgsdg", date: "2022-01-15 22:04:48" },
        { id: 4, descr: "dsdgsdg", date: "2023-01-15 22:04:48" },
      ],
      files: [...Array(5).keys()].map((id) => ({
        id,
        name: "IMG_20230320_185811.jpg",
        date: "2023-01-15 22:04:48",
        link: "/",
      })),
    })),
    isLoading: false,
  },
};
