// @/helpers/index.js
export function debounce(callback, wait) {
  let timeoutId = null;

  return (...args) => {
    window.clearTimeout(timeoutId);

    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

export function wrapInArray(v) {
  return v != null ? (Array.isArray(v) ? v : [v]) : [];
}

export const sortArrOfObj = (arr, key) =>
  [...arr].sort(({ [key]: a }, { [key]: b }) => (a < b ? -1 : a > b ? 1 : 0));

export const downloadUrl = (url, filename) => {
  const link = document.createElement("a");

  link.setAttribute("href", url);

  if (filename) {
    link.setAttribute("download", filename);
  } else {
    link.setAttribute("target", "_blank");
  }

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
