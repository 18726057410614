<template>
  <div
    class="dropzone"
    :class="{ dropzone__highlight: isDropzone }"
    @dragenter="onDragEnter"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isDropzone: false,
  }),
  methods: {
    onDragEnter(e) {
      if (!this.disabled) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    onDragOver(e) {
      if (!this.disabled) {
        this.isDropzone = true;

        e.stopPropagation();
        e.preventDefault();
      }
    },
    onDragLeave() {
      if (!this.disabled) {
        this.isDropzone = false;
      }
    },
    onDrop(e) {
      if (!this.disabled) {
        this.isDropzone = false;

        e.stopPropagation();
        e.preventDefault();

        this.$emit("drop", e);
      }
    },
  },
};
</script>
