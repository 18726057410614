//! @/store/_users.js

export default {
  namespaced: true,
  state: {
    users: [
      { id: 1, username: "romkamix" },
      { id: 2, username: "romkamix" },
      { id: 3, username: "romkamix" },
      { id: 4, username: "romkamix" },
    ],
    isLoading: false,
  },
};
