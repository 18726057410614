<template>
  <component :is="layout">
    <router-view />
    <noty-component />
  </component>
</template>

<script>
import { meta as metaInfo } from "@/plugins";
import * as layouts from "@/layouts";
import { NotyComponent } from "@/components";

const keys = Object.keys(layouts),
  index = keys.indexOf("Default"),
  default_layout = keys[index !== -1 ? index : 0];

export default {
  name: "App",
  metaInfo,
  components: {
    ...layouts,
    NotyComponent,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    },
  },
};
</script>
