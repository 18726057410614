//! @/store/_config.js

import { file } from "@/api";

export default {
  namespaced: true,
  state: {
    api: process.env.VUE_APP_API || "",
    locale: process.env.VUE_APP_LOCALE || "en",
    filesize: null,
  },
  actions: {
    limits() {
      file.limits();
    },
  },
};
