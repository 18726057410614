import { io } from "socket.io-client";

const socket = io(process.env.VUE_APP_SOCKET, {
  // withCredentials: true,
  reconnectionAttempts: 5,
});

socket.on("connect", () => {
  console.log(socket.id); // x8WIv7-mJelg7on_ALbx
});

socket.on("disconnect", () => {
  console.log(socket.id); // undefined
});

// socket.on("service:reload-page", () => location.reload());
socket.on("*", () => console.log("fdfs"));

export default socket;
