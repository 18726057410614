<template>
  <div class="grey darken-1 layout-empty">
    <div class="layout-empty__wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>
