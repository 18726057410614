<template>
  <form>
    <div class="p-inputgroup">
      <InputText
        :placeholder="$t('search')"
        v-model="search"
        :loading="isLoading"
      />
      <Button icon="pi pi-search" class="p-button-warning" />
    </div>
  </form>
</template>

<script>
import { InputText, Button } from "./_primevue";

export default {
  components: {
    InputText,
    Button,
  },
  props: {
    value: {
      default: undefined,
    },
  },
  data: () => ({
    search: "",
    isLoading: false,
  }),
};
</script>
