//! @/api/_ping.js

import { axios } from "@/plugins";

const base = "hosts";

export default {
  list: () => axios.get(base),
  store: (payload) => axios.post(base, payload),
  update: (id, payload) => axios.put(`${base}/${id}`, payload),
};
