// @/store/index.js
import Vue from "vue";

import Vuex from "vuex";
Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";

// modules
import auth from "./_auth";
import modal from "./_modal";
import config from "./_config";
import noty from "./_noty";
import users from "./_users";
import clients from "./_clients";
import projects from "./_projects";
import hosts from "./_hosts";
import websocket from "./_websocket";

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["auth.user"],
    }),
  ],
  modules: {
    auth,
    hosts,
    modal,
    config,
    noty,
    users,
    clients,
    projects,
    websocket,
  },
});
